<template>
    <div>
        <MoreInfo></MoreInfo>
        <h3 class="text-center mb-3">{{ officeName }} <span class="font-weight-light">({{ cityName }})</span></h3>
        <v-card class="elevation-1 mx-7">
            <v-card-title>
            Aportantes Registrados
            <v-spacer></v-spacer>
            <v-btn
            :to="$route.params._id+'/create'"
            class="mx-auto"
                elevation="2"
                color="primary"
            >Nuevo Aportante</v-btn>
            </v-card-title>
            <v-text-field
                class="mx-4"
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
            ></v-text-field>
            <v-data-table
                :headers="headers"
                :items="contributors"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-text':'Filas por Página',
                    'items-per-page-all-text': 'Todos'
                }"
                :headerProps="{
                    sortByText:'Ordenar por...'
                }"
                :no-data-text="'No hay datos disponibles'"
            >
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="editItem(item._id)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="sendItem(item._id)"
                            >
                                mdi-send
                            </v-icon>
                        </template>
                        <span>Transferir</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteItem(item._id)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import { prettyID, titleCase } from '@/modules/tools';


const store = new Storage();

import MoreInfo from '@/components/Layout/MoreInfo.vue'
export default {
    name: 'ContributorMain',
    components: {
        MoreInfo
    },
    data() {
        return {
            officeName: '',
            cityName: '',
            sortBy: 'name',
            sortDesc: false,
            dialog: false,
            dialogDelete: false,
            search: '',
            contributors: [],
            headers: [
          {
            text: 'NIT / Cédula',
            align: 'start',
            value: 'ID',
          },
          { text: 'Nombre', value: 'name' },
          { text: 'Celular', value: 'mobile' },
          { text: 'Email', value: 'email' },
          { text: 'ARL', value: 'ARL' },
          { text: 'Acciones', value: 'actions', sortable: false },
        ],
        }
    },
    async mounted() {
        this.officeName = await store.getData("office", this.$route.params._id);
        this.cityName = await store.getData("city", this.officeName.city);
        this.officeName = this.officeName.name;
        this.cityName = this.cityName.name;
        this.cityName = titleCase(this.cityName);
      const contributorData = await store.getData("contributor");
      const ARLData = await store.getData("ARL");
      contributorData.forEach(contributor => {
          if (contributor.office === this.$route.params._id) {
            ARLData.forEach(async ARL => {
            if (ARL._id === contributor.ARL) {
                contributor.ARL = ARL.name;
            }
            contributor.ID = await prettyID(contributor.ID);
            });
            this.contributors.push(contributor);
          }
      });
    },
    methods: {
        editItem(_id) {
            this.$router.push({ name: "Contributor Edit", params: {
                _id: this.$route.params._id,
                object_id: _id
            }}); 
        },
        async sendItem(_id) {
            const contributor = await store.getData("contributor", _id);
            const officeData = await store.getData("office");
            const oficeOptions = {};
            officeData.forEach(office => {
                oficeOptions[office._id] = office.name;
            });
            const { value: officeId } = await Swal.fire({
                title: "Transferir a Oficina",
                text: contributor.name,
                input: "select",
                inputOptions: oficeOptions,
                inputPlaceholder: 'Seleccione una Oficina',
                showCancelButton: true,
                confirmButtonText: `Transferir`,
                cancelButtonText: "Cancelar"
            });
            if (officeId) {
                Swal.fire({
                    icon: "warning",
                    title: "Transfiriendo Aportante",
                    html:
                    "Por favor espere mientras transferimos el aportante</b> " +
                    '<div class="progress mt-3"><div class="progress-bar" id="progressDataForm" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    stopKeydownPropagation: true
              });
              const data = {
                _id: _id,
                office: officeId
              };
              await store.updateData("contributor", [data], true, "misalud");
              Swal.fire({
                    icon: "success",
                    title: "Aportante transferido a otra oficina",
                    text: "Todos los datos han sido sincronizados"
                });
                this.contributors.forEach((item, index) => {
                    if (item._id === _id) {
                        this.contributors.splice(index, 1);
                    }
                });
            }
        },
        async deleteItem(_id) {
            const contributor = await store.getData("contributor", _id);
            Swal.fire({
                title: "¿Eliminar Aportante?",
                text: contributor.name,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#dc3545",
                cancelButtonColor: "#6c757d"
            }).then(result => {
                if (result.isConfirmed) {
                    Swal.fire({
                        icon: "warning",
                        title: "Eliminando Aportante",
                        html:
                            "Por favor espere mientras se elimina el aportante</b> " +
                            '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        stopKeydownPropagation: true
                    });
                    store.deleteData("misalud", "contributor", _id).then(() => {
                        Swal.fire({
                            icon: "success",
                            title: "Aportante Eliminado",
                            text: "Todos los datos han sido sincronizados"
                        })
                        this.contributors.forEach((item, index) => {
                            if (item._id === _id) {
                                this.contributors.splice(index, 1);
                            }
                        });
                    });
                }
            });
        }
    },
}
</script>